function add(url,prefix){
  if(Object.prototype.toString.call(url)!=='[object String]')return url;
  if(url.indexOf("<img")!==-1){
    url=url.replaceAll(/src=[\'\"]{1}([^\'\"]*)[\'\"]{1}/gi,(match,$1)=>{
        if($1.includes('http://')||$1.includes("https://")){
          return match;
        }

        return match.replaceAll($1,prefix+$1);
      //return  url.replaceAll(/src=[\'\"]?([^\'\"]*)[\'\"]?/gi,'src="'+prefix+'$1"')
      })
      return url;
  }
  if(url.indexOf(",")!==-1){
    return url.split(",").map(l=>process.env.VUE_APP_ZY_URL+l.replaceAll(prefix,"").replaceAll("/editor/", "/")).join(",");
  }
  return  process.env.VUE_APP_ZY_URL+url.replaceAll(prefix,"").replaceAll("/editor/", "/");
}
function remove(url){
  if(Object.prototype.toString.call(url)!=='[object String]')return url;
  return  url.replaceAll(process.env.VUE_APP_ZY_URL,"").replaceAll("/editor/", "/");
}
const fileReg=/(.webm|.webp|.mp4|.ogg|.m3u8|.avi|.png|.jpe?g|.gif|.svg|.html|.css|.xls.|.xlsx|.pdf)($|"|'|,){1}/
export function appendDomain(obj,domain) {
  if(Array.isArray(obj)){
    if(obj.length>=1){
        obj.forEach((item,idx)=>{
          if(fileReg.test(obj[idx])&&Object.prototype.toString.call(obj[idx]) === "[object String]"){
            obj[idx]=add(obj[idx],domain);
            }else{
            appendDomain(item,domain)
          }
        });

    }

  }else if(Object.prototype.toString.call(obj) === "[object Object]"){
    for(const key in obj){

      if(fileReg.test(obj[key])&&Object.prototype.toString.call(obj[key]) === "[object String]"){
        obj[key] =add(obj[key],domain);
      }else if(Object.prototype.toString.call(obj[key]) === "[object Object]"||Object.prototype.toString.call(obj[key]) === "[object Array]"){
        appendDomain(obj[key],domain)
      }
    }
  }
}
export function removeDomain(obj) {
  if(Array.isArray(obj)){
    if(obj.length>=1){

      obj.forEach((item,idx)=>{
        if(fileReg.test(obj[idx])&&Object.prototype.toString.call(obj[idx]) === "[object String]"){

          obj[idx]=remove(obj[idx]);
          }else{
          removeDomain(item)
        }
      });

  }
  }else if(Object.prototype.toString.call(obj) === "[object Object]"){
    for(const key in obj){

      if(fileReg.test(obj[key])&&Object.prototype.toString.call(obj[key]) === "[object String]"){

        obj[key] =remove(obj[key]);
      }else if(Object.prototype.toString.call(obj[key]) === "[object Object]"||Object.prototype.toString.call(obj[key]) === "[object Array]"){
        removeDomain(obj[key])
      }
    }
  }
}

